<template>
  <date-picker
    v-model="time1"
    :lang="lang"
    :format="format"
    @change="change"
    :type="type"
    :range.sync="range"
    :disabled-days="disableWeekends"
    :key="componentKey"
    :placeholder="customPlaceholder"
    :clearable="false"
    ref="datepicker"
    :popupClass="popupClass"
    :popupStyle="popupStyle"
    :default-value="new Date()"
  ></date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { DatePicker },
  props: [
    "referencia",
    "type",
    "customPlaceholder",
    "customRangePlaceholder",
    "range",
    "popupClass",
    "popupStyle",
    "defaultValue",
  ],
  data() {
    return {
      componentKey: 0,
      time1: "",
      time2: "",
      time3: "",
      // custom lang
      lang: {
        days: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        formatLocale: {
          monthsShort: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: this.customPlaceholder,
          dateRange: this.customRangePlaceholder,
        },
        feriados: null,
      },
      // custom range shortcuts
      shortcuts: [
        {
          text: "Today",
          onClick: () => {
            this.time3 = [new Date(), new Date()];
          },
        },
      ],
      timePickerOptions: {
        start: "00:00",
        step: "00:30",
        end: "23:30",
      },
      format: "DD-MM-YYYY",
    };
  },
  created() {
    this.$eventHub.$on("update:range", (checked) => this.changeRange(checked));
    this.$eventHub.$on("datepicker-cerrar", () =>
      this.$refs.datepicker.closePopup()
    );
  },
  mounted() {},
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    changeRange(checked) {
      this.range = checked;
    },
    disableWeekends(date) {
      if (!isNaN(date.getTime())) {
        if (this.feriados != null) {
          if (this.type != "month") {
            const day = new Date(date).getDay();
            if (day === 0 || day === 6) {
              return true;
            }
            let esFeriado = false;
            this.feriados.forEach(function (t) {
              if (
                date != null &&
                date.toJSON().split("T")[0] == t.toJSON().split("T")[0]
              ) {
                esFeriado = true;
              }
            });
            return esFeriado;
          } else {
            return null;
          }
        }
      }
    },
    getValue: function () {
      return this.time1;
    },
    change: function () {
      this.$emit("change", this.referencia);
      this.$refs.datepicker.closePopup();
    },
    cerrarDropdown() {
      this.$eventHub.$emit("dropdown-cerrar", this);
    },
  },
};
</script>

<style scoped>
/deep/.mx-input {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
  font-size: 1.1vw;
  background-color: #f2f2f2;
  height: 3.4vw;
  cursor: pointer;
  border-radius: 0;
}
/deep/ .mx-input-wrapper {
  height: 100%;
}
</style>
